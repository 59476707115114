html,body{
	height: 100%;
}

body{
	font-family: 'PT Sans';
	color: $brand-primary;
	font-size: 16px;
	line-height: 1.5;
}
h1{
	font-family: 'kijkuit';
	font-weight: bold;
}
a{
	color: $brand-primary;
}

img{
	max-width: 100%;
}
#tinymce {
	height: auto;
}
.content.row{
	margin-left: 0;
	margin-right: 0;
}


.masked {
	mask-image:  url(../images/mask.png);
	&.logo{
		mask-image:  url(../images/logo-mask.png);
	}
}

.default{
	.header-container{

		.title{
			color: #fff;
		}
		&.def{
			background-color: $brand-primary;
			.inner{
				height: 350px;
			}
		}

	}
	.mobile-header{
		background-color: $brand-red;
		min-height: 100px;

		color: #fff;
		h1{
			position: static;
			padding: 20px;
		}
	}
	.navbar-toggle{
		border-color: $brand-red;
		.icon-bar{
			background-color: $brand-red;
		}
	}
	#brand{
		color: $brand-red;
	}
	.nav-primary li.m-btn a{
		background-color: $brand-red;
	}
	main{
		color: $brand-primary;
		background-color: #fff;
	}
	#content{
		h1{
		color: $brand-orange;
	}}
	.intro, .info{
		h2{
			color: $brand-orange;
		}
		a{
			color: $brand-orange;
			border-color: $brand-orange;
		}
		.btn{
			background-color: $brand-orange;
			color: #fff;
			font-weight: bold;
			&:hover{
				background-color: $brand-primary;
			}
		}
	}
	.side{
		color: #fff;
		background-color: $brand-primary;
		hr{
			border-color: $brand-primary;
		}
		.btn{
			color:#fff;
			background-color: $brand-orange;
			border-color: $brand-orange;
			&:hover{
				background-color: $brand-orange-dark;
				border-color: $brand-orange-dark;
			}
		}
		a{
			color: #fff;
			border-color: $brand-primary-light;
			&:hover{
				border-color: #fff;
			}
		}
	}
	.related{
		background-color: $brand-primary;
		h1{
			color: #fff;
		}
		.img h2{
			background-color: $brand-orange;
		}
	}
}




.water{
	.navbar-toggle{
		border-color: #1c6789;
		.icon-bar{
			background-color: #1c6789;
		}
	}
	#brand{
		color: #1c6789;
	}
	.nav-primary li.m-btn a{
		background-color: #1c6789;
	}
	main{
		color: #fff;
		background-color: $water-light;
	}
	.intro, .info{
		h1,h2{
			color: $water-high;
		}
		a{
			color: $water-high;
			border-color: $water-light;
			&:hover{
				border-color: $water-hover;
			}
		}
		.btn{
			background-color: $water-high;
			color: $water-dark;
			border-color: $water-high;
			font-weight: bold;
			&:hover{
				background-color: $water-dark;
				color: $water-high;
				border-color: $water-dark;
			}
		}
	}
	.side{
		color: $water-dark;
		background-color: $water-high;
		hr{
			border-color: $water-light;
		}
		.btn{
			color:#fff;
			background-color: $water-light;
			border-color: $water-light;
			&:hover{
				background-color: $water-dark;
				border-color: $water-dark;
			}
		}
		a{
			color: $water-dark;
			border-color: $water-dark;
		}
	}
	.related{
		background-color: $water-dark;
		h1{
			color: $water-high;
		}
		.img h2{
			background-color: $water-light;
		}
	}
}
.ground{
	.navbar-toggle{
		border-color: $ground-high;
		.icon-bar{
			background-color: $ground-high;
		}
	}
	#brand{
		color: $ground-high;
	}
	.nav-primary li.m-btn a{
		background-color: $ground-high;
	}
	main{
		color: #fff;
		background-color: $ground-dark;
	}
	.intro, .info{
		h1,h2{
			color: $ground-high;
		}
		a{
			color: $ground-light;
			border-color: $ground-light;
			&:hover{
				border-color: $ground-hover;
			}
		}
		.btn{
			background-color: $ground-high;
			color: $ground-dark;
			border-color: $ground-high;
			font-weight: bold;
			&:hover{
				border-color: $ground-high;
			}
		}
	}
	.side{
		color: $ground-dark;
		background-color: $ground-high;
		hr{
			border-color: $ground-dark;
		}
		.btn{
			color:#fff;
			background-color: $ground-dark;
			&:hover{
				background-color: $ground-hover;
				border-color: $ground-hover;
			}
		}
		h2{
			color: $ground-hover;
		}
		a{
			color: $ground-dark;
			border-color: $ground-dark;
		}
	}
	.info{

	}
	.related{
		background-color: #302014;
		h1{
			color: $ground-high;
		}
		.img h2{
			background-color: $ground-high;
		}
	}
}
.air{
	.navbar-toggle{
		border-color: $air-high;
		.icon-bar{
			background-color: $air-high;
		}
	}
	#brand{
		color: $air-high;
	}
	.nav-primary li.m-btn a{
		background-color: $air-high;
	}
	main{
		color: $air-dark;
		h1{
			color: #fff;
		}
	}
	.intro, .info{
		h1,h2{
			color: $air-high;
		}
		a{
			color: $air-light;
			border-color: $air-light;
		}
    	p{
    		background: #fff;
	    }
	    .btn{
			background-color: $air-high;
			color: #fff;
			border-color: $air-high;
			font-weight: bold;
			&:hover{
				border-color: $air-dark;
				background-color: $air-dark;
			}
		}
	}
	.side{
		color: $air-dark;
		background-color: $air-high;
		hr{
			border-color: $air-dark;
		}
		.btn{
			color:#fff;
			background-color: $air-dark;
			&:hover{
				background-color: $air-hover;
				border-color: $air-hover;
			}
		}
		a{
			color: $air-dark;
			border-color: $air-dark;
		}
	}
	.related{
		background-color: #ecf7f9;
		h1{
			color: $air-high;
		}
		.img h2{
			background-color: $air-high;
		}
	}

}
.fire{
	.navbar-toggle{
		border-color: $fire-high;
		.icon-bar{
			background-color: $fire-high;
		}
	}
	#brand{
		color: $fire-high;
	}
	.nav-primary li.m-btn a{
		background-color: $fire-high;
	}
	main{
		color:#fff;
		background-color: $fire-dark;
	}
	.intro, .info{
		h1,h2{
			color: $fire-light;
		}
		a{
			color: #d88248;
			border-color: #d88248;
			&:hover{
				color: $fire-light;
				border-color: $fire-light;
			}
		}
		.btn{
			background-color: $fire-high;
			color: #fff;
			border-color: $fire-high;
			font-weight: bold;
			&:hover{
				border-color: #302014;
				background-color: #302014;
			}
		}
	}
	.side{
		color: #fff;
		background-color: $fire-high;
		hr{
			border-color: $fire-dark;
		}
		.btn{
			color:#fff;
			background-color: $fire-dark;
			border: none;
			&:hover{
				background-color: #4a2105;
			}
		}
		a{
			color: $fire-dark;
			border-color: $fire-dark;
			&:hover{
				color: $fire-light;
				border-color: $fire-light;
			}
		}
		h2{
			color: $fire-light;
		}
		p{
			color: #f4dbca;
		}
	}
	.related{
		background-color: #302014;
		h1{
			color: $fire-high;
		}
		.img h2{
			background-color: $fire-high;
		}
	}
}