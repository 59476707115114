
#brand{
	color:#a6052d;
	font-family: 'kijkuit';
	font-size: 2.5em;
	text-transform: uppercase;
	font-weight: bold;
	padding: 15px 0;
	-webkit-font-smoothing: antialiased !important;
}


.navbar-toggle{
	border: 3px solid $brand-red;
	margin-right: 0;
	.icon-bar{
		background-color: $brand-red;
	}
}
.nav-primary{
	text-transform: lowercase;
	font-weight: bold;
	li{
		a{
			color: #999999;
			padding-left: 10px;
			padding-right: 10px;
			&:hover{
				color: #010000;
				background-color: transparent;
			}
		}
		&.active{
			a{
				color: #010000;
				background-color: transparent;
			}
		}
		&.m-btn{
			a{
				color: #fff;
				background-color: $brand-red;
			}
		}
	}
}

#lang-nav{
    padding: 0;
    padding-top: 11px;
    margin: 0 -15px;
    li{
        list-style: none;
        float: left;
        width: 50%;
        &:last-child{
            border-right: none;
        }
        a{
            text-decoration: none;
            display: block;
            padding: 5px;
            text-align: center;
            color: $brand-primary;
        }
        &.current-menu-item{
            a{
                font-weight: bold;
                color: #fff;
                background: $brand-primary;
            }
        }
    }
    .code{
        display: none;

    }
}

@media only screen and (min-width: 768px) {

	header{
		position: relative;
		z-index: 150;
	}
	/*header{
		position: fixed;
		width: 100%;
		background-color: #fff;
		z-index: 150;
		.container{
			padding:0;
		}
	}
	.wrap{
		padding-top: 50px;
	}*/
	.nav-primary{
		position: relative;
	}
	.navbar-right{
		padding-right: 70px;
	}
	.navbar-collapse.collapse{
		padding: 0;
		
	}
	#lang-nav{
		position: absolute;
		top: 0;
		right: 0;
        li{
            width: auto;
            a{
                width: auto; height: auto;
                padding: 2px 5px;
                
            }
            &.current-menu-item{
                a{
                    background: transparent;
                    color: $brand-red;
                    span{
	                    border-bottom: 2px solid $brand-primary;
                    }
                }
            }
        }
        .code{
            display: inline-block;
        }
        .full{
            display: none;
        }
    }
}