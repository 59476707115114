footer{
	padding: 30px 0;
	a{
		border-bottom: 1px solid $brand-primary-light;
		&:hover{
			text-decoration: none;
			border-color: $brand-primary;
		}
	}
	.col{
		padding: 0;
	}
}

.footer-nav{
	.menu-item{
		a{
			display: inline-block;
			padding: 0;
			margin: 0  0 5px 15px;
			&:hover{
				background: transparent;
			}
		}
	}
}

.madeby{
	font-size: 14px;
	color: #5c5c5c;
	a{
		color: #5c5c5c;
	}
}
.fb-wrapper{
	margin-bottom: 15px;
	max-width: 100%;
	overflow: hidden;
}
.TA_restaurantWidgetGreen{
	display: inline-block;
}
#CDSWIDREST .wrapperLink .rightSide{
	width: auto !important;
}
#fourSq_widget_id_1448029948627_0_566{
	display: inline-block;
	vertical-align: top;
}

@media only screen and (min-width: 768px) {
	.fb-like{
		margin-right: -20px;
	}
	.footer-nav{
		float: none;
		.menu-item{
			a{
				margin: 0 15px 0 0 ;
			}
		}
	}
}