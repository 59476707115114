body#tinymce {
  margin: 12px !important;

	a{
		border-bottom: 1px solid $brand-primary;
		&:hover{
			text-decoration: none;
		}
	}
}
