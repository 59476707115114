.content{
	a{
		border-bottom: 1px solid $brand-primary-light;
		&:hover{
			text-decoration: none;
			border-color: $brand-primary;
		}
	}
}
.main{
	padding: 0;
}

// FRONTPAGE

.openinghours{
	background-color: $brand-primary;
	color: #fff;
	span{
		padding: 5px 7px;
		border-radius: 2px
	}
}
.block-content{
	padding: 15px 30px;
	h1{
		margin-top: 0;
	}
	ul{
		margin-left: -20px;
	}
	/*ul {
		list-style: none;
		padding-left: 0;
	    -moz-column-count: 2;
	    -moz-column-gap: 20px;
	    -webkit-column-count: 2;
	    -webkit-column-gap: 20px;
	    column-count: 2;
	    column-gap: 20px;
		li{
			-webkit-column-break-inside: avoid;
		    page-break-inside: avoid;
		    break-inside: avoid-column;
			&:before{
				content: '• ';
			}
		}
	}*/
}
.top{
	color: #fff;
	font-size: 18px;
	position: relative;
	background-size: cover;
	display: flex;
	align-items: center;
	a{
		color: #fff;
		border-color: #ccc;
		&:hover{
			border-color: #fff;
		}
	}
	&.logo.block-left{
		justify-content: flex-end;
		.block-content{
			text-align: center;
		}
	}
	strong{
		a{
			color: $brand-orange;
			border-bottom: none;
		}
	}
	.block-bg{
		position: absolute;
		bottom: 0; top: 0; left: 0; right: 0;
		opacity: 1;
		z-index: -1;
		background-size: cover;
		background-position: center;
	}
	.logo{
		opacity: 1;
		width: 90%;
	}
}
.nop{
	.block-bg{
		height:	225px;
		@media only screen and (min-width: 768px) {
			height: 300px;
		}
		@media only screen and (min-width: 1600px) {
			height: 360px;
		}
		.block-bg-img{
			height: 99%;
			opacity: 0.8;
			background-size: cover;
			background-position: center;
		}
	}
}

// SIDEBAR + HEADER PAGES

.img{
	padding: 0;
}
.intro{
	padding: 25px;
}
.side{
	padding: 25px;
	hr{
		margin-right: -25px;
		margin-left: -25px;
	}
}

hr{
	border-color: rgba(0,0,0,0.2)
}
.menukaart{
	.side{
		table{
			td{
			padding-left: 0;
			padding-bottom: 0;
			&:nth-child(2){
				width: 33%;
			}
		}}
	}
}


.related{
	h1{
		text-align: center;
		font-size: 25px;
		margin-bottom: 20px;
	}
	.img{
		position: relative;

		display: inline-block;
	    vertical-align: middle;
	    opacity: 0.9;

	    -webkit-transform: translateZ(0);
	    transform: translateZ(0);
	    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	    -webkit-backface-visibility: hidden;
	    backface-visibility: hidden;
	    -moz-osx-font-smoothing: grayscale;
	    -webkit-transition-duration: 0.3s;
	    transition-duration: 0.3s;
	    -webkit-transition-property: all;
	    transition-property: all;




		&:hover{
			    -webkit-transform: scale(1.05);
    			transform: scale(1.05);
    			z-index: 50;

    			opacity: 1;
		}
		a{
			display: block;
			border-bottom: 1px solid transparent;
		}
		h2{
			position: absolute;
			bottom: 0px;
			left: 0;
			color: #fff;
			background-color: $brand-orange;
			padding: 10px 15px;
			font-size: 18px;
			margin-bottom:0;
		}
	}
	&.onpage{
		.img a{
			border-bottom: none;
		}
	}

}
.header-container, .parallax-mirror{
	display: none;
}
.mobile-header{
	h1{
		position: absolute;
		left: 20px;
		font-size: 50px;
	}
}



.restaurant,.buitenbar, .menukaart{
	table{
		vertical-align: top;
		width: 100%;
		td{
			padding: 5px;
			vertical-align: top;

			td{
				&:nth-child(2){
					text-align: right;
				}
			}
		}
	}
}


.menukaart{
	table{
		vertical-align: top;
		width: 100%;
		td{
			padding: 5px;
			vertical-align: top;
			display: block;
			tr{
				border-bottom: 1px solid rgba(0,0,0,0.2);
				td{
					display: table-cell;
					&:nth-child(2){
						text-align: right;
					}
				}
			}

		}
	}
}




// ACTIVITEITEN

.page-template-template-display-subpages{
	main{
		background-color: $brand-primary;
		color: #fff;
		a{
			color: #fff;
			border-color: $brand-primary-light;
			&:hover{
				border-color: #fff;
			}
		}
	}
}

.title{
	font-size: 5rem;
	img{
		max-width: 90px;
	}
}


#text-bg, #middle-element,#background-element{
	position:absolute;
	background-repeat:no-repeat;
	background-size:cover;
	height:100%;
	width:100%;
	background-position:center top;
}
#text-bg{
	left: 0;
	top: -50px;
	z-index: 40;
}
#middle-element{
	bottom: 0;
	left: 0;
	right: -1px;
	z-index: 30;
	img{
		position: absolute;
		bottom: 0;
	}
}
#background-element{
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 20;
	img{
		width: 100%;
		position: absolute;

	}
}
#front-element{
	z-index: 60;
}

.extra_info{
	.info{
		margin-top: 15px;
		margin-bottom: 15px;
	}
}


// CONTACT

.contact{
	iframe{
		margin-top: 50px;
	}
}

.footer_logos{
	margin-bottom: 50px;
}

// RESERVEREN

.checkbox-columns>div{
	list-style: none;
	padding-left: 0;
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
}

.caldera-grid .radio input[type=radio], .caldera-grid .radio-inline input[type=radio], .caldera-grid .checkbox input[type=checkbox], .caldera-grid .checkbox-inline input[type=checkbox]{
	margin-top: 5px !important;
}
.caldera-grid .btn{
	background-color: $brand-orange;
	border-color: $brand-orange-dark;
}

@media only screen and (min-width: 768px) {

	.klein{
		.header-container{
			.inner{
				.title{
					font-size: 6rem;
				}
			}
		}
	}

	.home-block{
		//width: 50%;
		//float: left;
		.block-content{
			max-width: 570px;
			padding: 35px;
			margin: auto;
		}
		&.block-left{
			.block-content{
				//float: right;
			}
		}
		&.block-right{
			.block-content{
				//float: left;
			}
		}
		h1{
			font-size: 55px;
		}
	}


	.intro{
		padding-left: 0px;
	    padding-right: 25px;
	    padding-bottom: 25px;
	    padding-top: 0px;
	    h1{
	    	margin-top: 0;
	    }
	}
	.side{
		margin-top: 50px;
	}
	.related{
		padding: 50px 25px;
		h1{
			margin-top: 0;
		}
	}
	.extra_info{
		.info{
			padding: 0;
			&:first-child{
				padding-right:15px;
			}
			&:last-child{
				padding-left: 15px;
			}
		}
	}

	.parallax-mirror{
		display: block;
	}
	.header-container{
		/*background-position: center center;*/
		display: block;
		position: relative;
		/*background-size: cover;*/
		max-width: 2200px;
		overflow: hidden;
		.container{
			padding-left: 0;
			padding-right: 0;
		}
		#front-element{
			position: absolute;
			bottom: 0;
			top: 0px;
			left: 0;
			right: 0;
			z-index: 60;
			img{
				max-width: none;
				max-height: 100%;
			}
		}
		.title{
			left: 10px;
			top: 50%;
			transform: translate(0, -65%);
			position: absolute;
			//text-shadow: 1px 1px 15px rgba(0,0,0,0.5);
			z-index:30;
			img{
				margin-right: 10px;
				margin-left: 10px;
			}
		}
		.inner{
			position: relative;
			height: 440px;
			transition: height 0.4s;
			z-index: 50;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: left center;
			left: -30px;
			&:before{
				background: $brand-primary;
			}
		}
	}
	.mobile-header{
		display: none;
	}

	#content{
		margin-top: -20px;
	    position: relative;
	    z-index: 200;
	}
	.title{
		font-size: 6.25rem;
		img{
			max-width: 130px;
		}
	}



	/*.page{
		table{
			td{
				width: 50%;
				float: left;
				tr{
					td{
						float: none;
						width: auto;
					}
				}
			}
		}
	}*/
}
@media only screen and (min-width: 1024px) {
	.title{
		font-size: 8rem;
	}
	.menukaart table>tbody>tr{
		display: flex;
		td{
			flex: 2 auto;
		}
		td:nth-child(1){
			flex-grow: 2;
		}
	}
	.page{
		.cfdatepicker.dropdown-menu{
			z-index: 30 !important;
		}
		.cfdatepicker table tr{
			//display: table-row;
		}
	}

	.restaurant, .menukaart, .buitenbar{
		table{
			td{
				width: 48%;
				tr{

					td{
						width: auto;
					}
				}
			}
		}
	}

	.checkbox-columns>div{
	    -moz-column-count: 3;
	    -moz-column-gap: 20px;
	    -webkit-column-count: 3;
	    -webkit-column-gap: 20px;
	    column-count: 3;
	    column-gap: 20px;
	}
}
@media only screen and (min-width: 1200px) {

	.header-container{
		.inner{
			height: 480px;
		}

	}
	.title{
		font-size: 10rem;
		img{
			max-width: 170px;
		}
	}

}
@media only screen and (min-width: 1400px) {

	.header-container{
		.inner{
			height: 520px;
		}
		#front-element{
			img{
				max-height: auto;
				max-width: 100%;
			}
		}
	}
	.title{
		font-size: 10rem;
		img{
			max-width: 200px;
		}
	}
	#content{
		margin-top: -80px;
	}

}
@media only screen and (min-width: 1600px) {

	.header-container{
		.inner{
			height: 650px;
		}
	}
}

@media only screen and (min-width: 2000px) {

	.header-container{
		.inner{
			height: 750px;
		}
	}
}

