#fullsized_go_prev, #fullsized_go_next, #fullsized_close, #fullsized_fullscreen {
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
}
#fullsized_go_prev {
  left: 25px;

  width: 1em;
  height: 1em;
  border-right: 0.2em solid #FFF;
  border-top: 0.2em solid #FFF;
  transform: rotate(-135deg);
}
#fullsized_go_next {
  right: 25px;

  width: 1em;
  height: 1em;
  border-right: 0.2em solid #FFF;
  border-top: 0.2em solid #FFF;
  transform: rotate(45deg);

}
#fullsized_close {
  top: 15px;
  right: 15px;
  &:hover{
    text-decoration: none;
  }
  &:before{
     content: "×";
     font-size: 3em;
     line-height: 0.6em;
  }
}
#fullsized_fullscreen {
  //top: 0;
  //right: 40px;
  //background-color: green;
  left: -9999999px;
}

:fullscreen #fullsized_fullscreen { background-color: blue; }
:-webkit-full-screen #fullsized_fullscreen { background-color: blue; }
:-moz-full-screen #fullsized_fullscreen { background-color: blue; }



@media (min-width: 768px) {

  #fullsized_go_next, #fullsized_go_prev{
      width: 1.5em;
      height: 1.5em;
      border-width: 0.3em;
  }


}