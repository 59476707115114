// Glyphicons font path
$icon-font-path:        "../fonts/";
$font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #1c3136;
$brand-primary-light:   #aaa;
$brand-red:             #a6052d;
$brand-green:           #085331;
$brand-green-light:     #04a96d;
$brand-orange:          #ff7800;
$brand-orange-dark:     #dd6c1d;

$water-dark:            #022a3e;
$water-light:           #164d69;
$water-high:            #ebc028;
$water-hover:           #d1ae39;

$ground-dark:           #452d1d;
$ground-light:          #d88248;
$ground-high:           #93ae00;
$ground-hover:          #382211;

$air-dark:              #1c6789;
$air-light:             #0091a9;
$air-high:              #14b8db;
$air-hover:             #195977;

$fire-dark:           #653418;
$fire-light:          #fbb859;
$fire-high:           #c73f10;

// Fonts

@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,400italic,700,700italic);


@font-face {
    font-family: 'kijkuit';
    src: url($font-path+'Kijkuit-Bold.eot');
    src: url($font-path+'Kijkuit-Bold.eot?#iefix') format('embedded-opentype'),
         url($font-path+'Kijkuit-Bold.woff') format('woff'),
         url($font-path+'Kijkuit-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'kijkuit';
    src: url($font-path+'Kijkuit-Italic.eot');
    src: url($font-path+'Kijkuit-Italic.eot?#iefix') format('embedded-opentype'),
         url($font-path+'Kijkuit-Italic.woff') format('woff'),
         url($font-path+'Kijkuit-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'kijkuit';
    src: url($font-path+'Kijkuit-Regular');
    src: url($font-path+'Kijkuit-Regular.eot?#iefix') format('embedded-opentype'),
         url($font-path+'Kijkuit-Regular.woff') format('woff'),
         url($font-path+'Kijkuit-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kijkuit';
    src: url($font-path+'Kijkuit-BoldItalic');
    src: url($font-path+'Kijkuit-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url($font-path+'Kijkuit-BoldItalic.woff') format('woff'),
         url($font-path+'Kijkuit-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
