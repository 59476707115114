// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}


@media only screen and (min-width: 768px) {
	.flex {
	    display: flex;
	    flex-flow: row wrap;
	}
	.item {
	    flex: 1 auto;
	    width: 50%;
	}

}


@media only screen and (min-width: 1200px) {
	.item {
	    width: auto;
	}

}

@media only screen and (min-width: 768px) and (max-width: 992px) {

	.container{
		width: 98%;
	}

}